<template>
  <div>
    <b-card>
      <template #header>
        <h5 class="card-title">Tüm Müşteriler</h5>
        <div>
          <input
            type="search"
            class="form-control form-control-sm"
            placeholder="ara"
          />
        </div>
      </template>
      <div class="ml-n2 mr-n2">
        <b-table
          class="position-relative"
          empty-text="Kullanıcı Bulunmuyor"
          :items="datasource.customers.table_items"
          :fields="datasource.customers.table_columns"
          responsive
          show-empty
          small
        >
          <template #cell(col_user)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{
                  name: 'customer-update',
                  query: { customer: data.value.customer_uniq },
                }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.value.title }}
              </b-link>
              <small class="text-muted">@{{ data.value.customer_code }}</small>
            </b-media>
          </template>
          <template #cell(col_lastaction)="data">
            <div class="d-flex">
              <b-link
                :to="{
                  name: 'customer-update',
                  query: { customer: data.value.customer_uniq },
                }"
                class="font-weight-bold d-block text-nowrap"
              >
                <button class="btn btn-primary btn-sm">Profil</button>
              </b-link>
            </div>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import api from "@/service/api";
import utils from "@/service/utils";
import _ from "lodash";
export default {
  components: {
    vSelect,
    flatPickr,
  },
  data() {
    return {
      datasource: {
        loaded: false,
        customers: {
          stack_data: [],
          table_items: [],
          table_columns: [
            { key: "col_user", label: "" },
            { key: "col_email", label: "Email", sortable: true },
            { key: "col_gsm", label: "GSM", sortable: true },
            { key: "col_lastaction", label: "" },
          ],
        },
      },
    };
  },
  created() {
    if (!utils.userHasRole("list_customer")) {
      this.$router.push("/not-found");
      return;
    }
    utils.setTitle("Müşteri Listesi");
    this.getCustomers();
  },
  methods: {
    getCustomers() {
      let filter = { keyword: "", type: "customer", currentpage: 1 };
      api.action("searchCustomer", filter).then((q) => {
        if (q.success) {
          this.datasource.customers.stack_data = q.data.customers;
          q.data.customers.forEach((user) => {
            let item = {
              col_user: user,
              col_email: user.email_primary,
              col_gsm: user.gsm_primary,
              col_lastaction: "",
            };
            this.datasource.customers.table_items.push(item);
          });
        } else {
          this.notificationToast("warning", "Bilgi", q.message);
        }
      });
    },
    moment: function () {
      return moment();
    },
    userHasRole(rolename) {
      return utils.userHasRole(rolename);
    },
    shortUserName(username) {
      if (username == null) return;
      let result = "";
      if (username.includes(" ")) {
        result = username.split(" ")[0][0] + "" + username.split(" ")[1][0];
      } else {
        result = username[0];
      }
      return result;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>